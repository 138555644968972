import React from 'react';

export const Web = (props) => {
	return (
		<svg
			viewBox="0 -25 424 424"
			className={props.className}
			style={props.style}
			width={props.size ? props.size : '24px'}
			height={props.size ? props.size : '24px'}
		>
			<path
				d="M167.29 272.133a6.972 6.972 0 0 1-4.532-1.672l-45.297-38.57a7 7 0 0 1 0-10.66l45.297-38.57a7 7 0 0 1 9.867.788c2.504 2.946 2.152 7.363-.793 9.867l-39.035 33.243 39.035 33.242a7 7 0 0 1-4.543 12.332zm89.42 0c-1.98 0-3.948-.836-5.331-2.465a7 7 0 0 1 .789-9.867l39.035-33.242-39.035-33.243a7 7 0 0 1 9.074-10.656l45.297 38.57a7 7 0 0 1 0 10.66l-45.297 38.57a6.972 6.972 0 0 1-4.531 1.673zm-60.745 28.93a7.002 7.002 0 0 1-6.86-8.445l28.23-134.036a7 7 0 0 1 13.7 2.887l-28.23 134.035a7.001 7.001 0 0 1-6.84 5.559zm0 0"
				data-original="#000000"
				className="active-path"
				data-old_color="#1565c0"
				fill="#1565c0"
			/>
			<path
				d="M377 374.086H47c-25.914 0-47-21.082-47-47V47C0 21.086 21.086 0 47 0h330c25.914 0 47 21.086 47 47v280.086c0 25.918-21.086 47-47 47zM47 14c-18.195 0-33 14.805-33 33v280.086c0 18.195 14.805 33 33 33h330c18.195 0 33-14.805 33-33V47c0-18.195-14.805-33-33-33zm0 0"
				data-original="#000000"
				className="active-path"
				data-old_color="#1565c0"
				fill="#1565c0"
			/>
			<path
				d="M417 112.09H7a7.001 7.001 0 0 1 0-14h410a7.001 7.001 0 0 1 0 14zM119.602 78.594c-12.211 0-22.153-9.942-22.153-22.153 0-12.214 9.942-22.152 22.153-22.152 12.214 0 22.152 9.938 22.152 22.152 0 12.211-9.938 22.153-22.152 22.153zm0-30.305c-4.493 0-8.153 3.656-8.153 8.152s3.66 8.153 8.153 8.153c4.496 0 8.152-3.656 8.152-8.153s-3.656-8.152-8.152-8.152zM51.539 78.38c-12.215 0-22.152-9.938-22.152-22.152 0-12.215 9.937-22.153 22.152-22.153 12.215 0 22.152 9.938 22.152 22.153 0 12.214-9.937 22.152-22.152 22.152zm0-30.305c-4.492 0-8.152 3.66-8.152 8.153 0 4.496 3.66 8.152 8.152 8.152 4.496 0 8.152-3.656 8.152-8.152 0-4.493-3.656-8.153-8.152-8.153zm136.125 30.73c-12.21 0-22.148-9.937-22.148-22.152 0-12.21 9.937-22.148 22.148-22.148 12.215 0 22.152 9.937 22.152 22.148 0 12.215-9.937 22.153-22.152 22.153zm0-30.304c-4.492 0-8.148 3.656-8.148 8.152s3.656 8.153 8.148 8.153c4.496 0 8.152-3.657 8.152-8.153s-3.656-8.152-8.152-8.152zm0 0"
				data-original="#000000"
				className="active-path"
				data-old_color="#1565c0"
				fill="#1565c0"
			/>
		</svg>
	);
};
