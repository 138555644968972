import React from 'react';

export const Linkedin = (props) => {
	return (
		<svg
			{...props}
			viewBox="0 0 112.196 112.196"
			className={props.className}
			style={props.style}
			width={props.size ? props.size : '24px'}
			height={props.size ? props.size : '24px'}
		>
			<circle cx="56.098" cy="56.097" r="56.098" fill="#f1f2f2" />
			<path
				d="M89.616,60.611v23.128H76.207V62.161c0-5.418-1.936-9.118-6.791-9.118 c-3.705,0-5.906,2.491-6.878,4.903c-0.353,0.862-0.444,2.059-0.444,3.268v22.524H48.684c0,0,0.18-36.546,0-40.329h13.411v5.715 c-0.027,0.045-0.065,0.089-0.089,0.132h0.089v-0.132c1.782-2.742,4.96-6.662,12.085-6.662 C83.002,42.462,89.616,48.226,89.616,60.611L89.616,60.611z M34.656,23.969c-4.587,0-7.588,3.011-7.588,6.967 c0,3.872,2.914,6.97,7.412,6.97h0.087c4.677,0,7.585-3.098,7.585-6.97C42.063,26.98,39.244,23.969,34.656,23.969L34.656,23.969z M27.865,83.739H41.27V43.409H27.865V83.739z"
				fill="#007ab9"
			/>
		</svg>
	);
};
